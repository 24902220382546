$font_url: $fontRoot !default;

// plus jakarta sans
@font-face {
    font-family: "Plus Jakarta Sans";
    src:
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-Regular.woff") format("woff"),
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-Regular.ttf") format("truetype"),
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-Regular.svg#PlusJakartaSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src:
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-SemiBold.woff") format("woff"),
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-SemiBold.ttf") format("truetype"),
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-SemiBold.svg#PlusJakartaSans-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src:
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-Bold.woff") format("woff"),
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-Bold.ttf") format("truetype"),
        url("#{$font_url}/plus-jakarta-sans/PlusJakartaSans-Bold.svg#PlusJakartaSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// roboto font
@font-face {
    font-family: "Roboto";
    src:
        url("#{$font_url}/roboto/Roboto-Regular.woff") format("woff"),
        url("#{$font_url}/roboto/Roboto-Regular.ttf") format("truetype"),
        url("#{$font_url}/roboto/Roboto-Regular.svg#Roboto-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src:
        url("#{$font_url}/roboto/Roboto-Medium.woff") format("woff"),
        url("#{$font_url}/roboto/Roboto-Medium.ttf") format("truetype"),
        url("#{$font_url}/roboto/Roboto-Medium.svg#Roboto-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src:
        url("#{$font_url}/roboto/Roboto-Bold.woff") format("woff"),
        url("#{$font_url}/roboto/Roboto-Bold.ttf") format("truetype"),
        url("#{$font_url}/roboto/Roboto-Bold.svg#Roboto-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
