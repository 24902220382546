

$gap: 30px;
$column: 1;
$duration: 1s;


@for  $i from 1 through 60 {
  .infinite-scroll-duration-#{$i} { 
    animation-duration: $i * 2s !important;
  }
}


.infinite-scroll-init { 
  animation-fill-mode: forwards !important;
  animation-direction: alternate-reverse !important; 

  &.infinite-scroll-horizontal { 
    animation:  horizontal linear infinite;
  }

  &.infinite-scroll-horizontalAlternate { 
    animation:  horizontalAlternate linear infinite;
  }

  &.infinite-scroll-vertical { 
    animation:  vertical linear infinite;
  }

  &.infinite-scroll-verticalAlternate { 
    animation:  verticalAlternate linear infinite;
  }

}


.infinite-scroll { 
  gap: 12px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: grid;
  overflow: hidden;
  position: relative;
  place-items: center;
  grid-template-columns: 1fr;


  &-col { 
    gap: 12px;
    height: auto;
    display: flex;
    width: calc(120px * 3);

  }


  &-card { 
    width: 120px;
    height: auto;
    flex-shrink: 0;
    border-radius: 4px;
    position: relative;

    img { 
      width: 120px;
      height: auto;
    }
  }
}


// responsive breakpoints 

@include media-breakpoint-up(lg) { 
  .infinite-scroll { 
      gap: $gap;
      grid-template-columns: repeat(3,1fr);

      &-col { 
        gap: $gap;
        width: auto;
        flex-direction: column;
        height: calc(194px * 3);
      }

      &-card { 
        width: auto;
        height: 170px;
        flex-shrink: 1;

        img { 
          width: auto;
          height: auto;
        }
      } 
  }

  // animation initalization
  .infinite-scroll-init{

    &.infinite-scroll-lg-vertical { 
      animation:  vertical linear infinite;
    }

    &.infinite-scroll-lg-verticalAlternate { 
      animation:  verticalAlternate linear infinite;
    }

  }
  
}


@include media-breakpoint-up(xxl) { 
  .infinite-scroll { 
      &-card { 
        height: 194px;
      } 
  }

  // animation initalization
  .infinite-scroll-init{

    &.infinite-scroll-lg-vertical { 
      animation:  vertical linear infinite;
    }

    &.infinite-scroll-lg-verticalAlternate { 
      animation:  verticalAlternate linear infinite;
    }

  }
  
}

@include media-breakpoint-down(lg) { 
  .infinite-scroll { 
    width: 160%;
    margin-left: -30%;
  }
}



// keyframes of infinite tier animation 
@keyframes horizontal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-120px * 5));
  }
}


@keyframes horizontalAlternate {
  from {
    transform: translateX(calc(-120px * 5));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes vertical {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-194px * 5));
  }
}


@keyframes verticalAlternate {
  from {
    transform: translateY(calc(-194px * 5));
  }
  to {
    transform: translateY(0);
  }
}
