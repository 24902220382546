.description-block { 
    gap: 19px;
    display: flex;

.icon  { 
    font-size: 1.5rem;
    margin-top: .5rem;
    display: inline-block;
}

.info { 
    h6 {
        margin: 0px;
        font-size: 1rem;
        line-height: 2rem;
    }
    p { 
        font-size: 0.75rem;
        line-height: 1.25rem;
    }
}
}