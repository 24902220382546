

.card {

  &-title {
      a {
          color: inherit;
          &:hover {
              color: currentColor;
          }
      }
      &-group {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
  }
  &-divider {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      border-top: 1px dashed var(--#{$prefix}border-color);
  }
  &.has-shadow {
      border: 0;
      box-shadow:
          -4px -4px 36px rgba(192, 192, 192, 0.12),
          4px 4px 36px rgba(192, 192, 192, 0.16);
  }

  &-overlay {
      &::after {
          content: "";
          z-index: 5;
          top: 0;
          right: 12px;
          width: 11.75rem;
          height: 100%;
          position: absolute;
          background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      }

      &-bg-1,
      &-bg-2,
      &-bg-3,
      &-bg-4 {
          z-index: 1;
          position: relative;
          &::after {
              content: "";
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: -1;
              position: absolute;
              background-size: contain;
              background-repeat: no-repeat;
          }
      }

      &-bg-1 {
          &::after {
              background-image: url("#{$coreRoot}mask/mask-circle-1.png");
          }
      }
      &-bg-2 {
          &::after {
              background-image: url("#{$coreRoot}mask/mask-circle-2.png");
          }
      }

      &-bg-3 {
          &::after {
              background-image: url("#{$coreRoot}mask/mask-circle-3.png");
          }
      }
      &-bg-4 {
          &::after {
              z-index: -2;
              background-image: url("#{$coreRoot}mask/mask-bg-1.png");
          }
      }
  }

  &-mask {
      z-index: 1;
      overflow: hidden;
      position: relative;

      &-one {
          z-index: 1;
          overflow: hidden;
          position: relative;

          &::before,
          &::after {
              content: "";
              z-index: -1;
              opacity: 0.2;
              position: absolute;
              background-size: contain;
              background-repeat: no-repeat;
          }

          &::before {
              top: 0;
              left: 0;
              width: 365px;
              height: 100%;
              background-image: url("#{$coreRoot}mask/mask-circle-4-a.png");
          }
          &::after {
              bottom: 0;
              right: -15px;
              width: 180px;
              height: 295px;
              background-image: url("#{$coreRoot}mask/mask-circle-4-b.png");
          }
      }

      &-two {
          z-index: 1;
          overflow: hidden;
          position: relative;
          &::before {
              content: "";
              z-index: -1;
              position: absolute;
              background-size: contain;
              background-repeat: no-repeat;
          }

          &::before {
              top: 0;
              left: 0;
              width: 100%;
              height: 300px;
              background-image: url("#{$coreRoot}mask/mask-layer-1.png");
          }
      }
  }

  &-journey {
      position: relative;
      border-radius: 0.75rem;
      &-content {
          padding: 1.5rem;

          .heading {
              gap: 16px;
              display: flex;
              align-items: center;
              span {
                  &:nth-child(1) {
                      color: $dark;
                      font-weight: bold;
                      line-height: 2rem;
                      font-size: 1.25rem;
                      letter-spacing: -0.015em;
                  }
                  &:nth-child(2) {
                      color: $primary;
                      font-size: 0.875rem;
                      line-height: 1.375rem;
                      letter-spacing: 0.01em;
                      text-transform: capitalize;
                  }
              }
          }

          blockquote {
              margin: 0px;

              p {
                  font-size: 1rem;
                  line-height: 1.5rem;
              }
          }
      }
  }

  &-pricing {
      overflow: hidden;
      padding: 1.875rem;
      border-radius: 1rem;
      background-color: $white;

      &-heading {
          gap: 16px;
          display: flex;
          align-items: center;
          padding-bottom: 1rem;
          margin-bottom: 1.5rem;
          border-bottom: 1px solid #faf9ff;

          .icon {
              width: 62px;
              height: 62px;
              display: flex;
              overflow: hidden;
              align-items: center;
              border-radius: 0.5rem;
              justify-content: center;
              background-color: $dark;
          }

          .info {
              h3 {
                  margin: 0;
                  color: $dark;
                  font-weight: bold;
                  line-height: 2rem;
                  font-size: 1.25rem;
                  letter-spacing: -0.015em;
                  text-transform: capitalize;
              }

              span {
                  font-size: 1rem;
                  color: $body-color;
                  line-height: 1.5rem;
              }
          }
      }

      &-content {
          gap: 1.5rem;
          display: flex;
          flex-direction: column;
          .price-tag {
              color: $dark;
              font-weight: bold;
              font-size: 1.5rem;
              line-height: 2.5rem;

              span {
                  color: $body-color;
                  font-weight: 400;
                  font-size: 1.125rem;
                  line-height: 1.75rem;
              }
          }
      }

      &-info {
          li {
              margin-bottom: 0.5rem;
              p {
                  color: $dark;
                  font-size: 1rem;
                  line-height: 1.5rem;
                  position: relative;
                  padding-left: 1.25rem;

                  &::before {
                      content: "";
                      left: 0px;
                      top: 0.5rem;
                      width: 0.5rem;
                      height: 0.5rem;
                      overflow: hidden;
                      border-radius: 50%;
                      position: absolute;
                      display: inline-block;
                      background-color: $dark;
                  }

                  &.mute {
                      color: $body-color;

                      &::before {
                          background-color: $body-color;
                      }
                  }
              }
          }
      }
  }

  &-tools {
      width: 100%;
      height: 100%;
      max-height: 20rem;
      position: relative;
      border-radius: 1rem;
      background-color: #fff;
      img {
          width: 100%;
          height: 100%;
          object-fit: contain;
      }
  }
}

.team-card {
  position: relative;

  &-cover {
      border-radius: 1rem;

      img {
          width: 100%;
          border-radius: 1rem;
      }
  }

  &-details {
      gap: 8px;
      display: flex;
      padding: 1.5rem;
      position: relative;
      align-items: center;
      margin: -60px 24px 0;
      border-radius: 0.75rem;
      background-color: $white;
      justify-content: space-between;
      box-shadow: 4px 4px 36px 0px #c0c0c029;
      .info {
          span {
              font-size: 0.875rem;
              line-height: 1.5rem;
              letter-spacing: -0.01em;
          }
      }
  }
}

.explore-card {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
  border: 1px solid #e5e7eb;

  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

.tools-card {
  position: relative;

  &-children {
      position: absolute;
  }

  &-2 {
      .tools-card {
          &-children {
              right: 50px;
              bottom: 75px;
          }
      }
  }
}

.card-blog {
  .card {
      &-image {
          overflow: hidden;
          position: relative;
          border-radius: 0.5rem;

          img {
              transform: scale(1);
              transition: all 0.2s linear;
          }
      }
  }

  &:hover {
      .card-image {
          img {
              transform: scale(1.1);
          }
      }
  }
}

.card {
  // Business Analytics

  &-shadow-yellow {
      box-shadow: 8px 7px 0px 3px rgba(88, 77, 39, 1);
  }
  &-shadow-cyan {
      box-shadow: 8px 7px 0px 3px rgba(27, 83, 79, 1);
  }

  &-shadow-purple {
      box-shadow: 8px 7px 0px 3px rgba(84, 44, 73, 1);
  }

  &-shadow-blue {
      box-shadow: 8px 7px 0px 3px rgb(43, 31, 118, 1);
  }

  &-platform {
      border: 4px solid #e1cbfd;

      @media (min-width: 1200px) {
          border-bottom: 0px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
      }
  }
}

// responsive breakpoints

@include media-breakpoint-up(lg) {
  .card {
      &-tools {
          max-height: 25rem;
      }
  }

  .team-card {
      &-details {
          padding: 2rem 1.5rem;
      }
  }
}

@include media-breakpoint-up(md) {
  .card {
      &-journey {
          &-content {
              padding: 1.875rem;
          }
      }

      &-pricing {
          &-heading {
              margin-bottom: 1.875rem;
          }

          &-content {
              gap: 1.875rem;
          }

          // variants
          &-lg {
              .card-pricing {
                  &-heading {
                      margin-bottom: 2.25rem;
                      .icon {
                          width: 4.5rem;
                          height: 4.5rem;
                      }
                      .info {
                          h3 {
                              font-size: 1.5rem;
                              line-height: 2.5rem;
                          }
                          span {
                              font-size: 1.125rem;
                              line-height: 1.75rem;
                          }
                      }
                  }

                  &-content {
                      .price-tag {
                          color: $primary;
                          font-size: 2.5rem;
                          line-height: 3.75rem;
                          letter-spacing: -0.02em;

                          span {
                              line-height: 2rem;
                              font-size: 1.25rem;
                          }
                      }
                  }

                  &-info {
                      li {
                          p {
                              font-size: 1.125rem;
                              line-height: 1.75rem;
                          }
                      }
                  }
              }
          }
      }
  }
}

@include media-breakpoint-down(lg) {
  .card {
      &-overlay {
          &::after {
              display: none;
          }
      }
  }
}

@include media-breakpoint-down(sm) {
  .nl-brand {
      img {
          width: auto;
          max-height: 24px;
      }
  }
}
