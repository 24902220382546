.password-toggle{
    &.is-shown{
        .on{
            display: none;
        }
        .off{
            display: block;
        }
    }
}
