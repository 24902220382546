.#{$prefix}timeline {

    &-item {
        &:not(:last-child) {
            padding-bottom: 1.625rem;
        }

        &-inner {
            gap: 8px;
            display: flex;
        }
    }

    &-symbol {
        z-index: 1;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 50%;
        position: relative;

        &::after {
            top: 50%;
            left: 50%;
            width: 50%;
            height: 50%;
            z-index: 2;
            content: "";
            position: absolute;
            border-radius: 50%;
            background-color: currentColor;
            transform: translate(-50%, -50%);
        }
    }

    &-group {
        display: flex;
        flex-wrap: wrap;

        &.has-divider {
            .#{$prefix}timeline-group {
                &-item {
                    @include media-breakpoint-up(lg) {
                        z-index: 1;
                        position: relative;
                    }

                    &::after {
                        @include media-breakpoint-up(lg) {
                            left: 50%;
                            top: 32px;
                            content: "";
                            width: 100%;
                            z-index: -1;
                            height: 1px;
                            position: absolute;
                            background-color: var(--#{$prefix}border-color);
                        }
                    }

                    &:last-child {
                        &::after {
                            @include media-breakpoint-up(lg) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &-item {
            @include media-breakpoint-up(sm) {
                width: 50%;
            }
            @include media-breakpoint-up(lg) {
                width: $timeline-group-item-width;
            }
        }
    }

    &.has-border { 

        .#{$prefix}timeline { 

            &-item { 
                position: relative;

                &::after {
                    content: "";
                    top: 6px;
                    left: 7px;
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    background-color: var(--#{$prefix}border-color);
                }

                &:last-child { 
                    &::after { 
                        display: none;
                    }
                }
            }
        }
    }
}

.timeline-dots {
    li {
        position: relative;

        .circle {
            top: 12px;
            z-index: 1;
            left: -24px;
            display: flex;
            overflow: hidden;
            border-radius: 50%;
            position: absolute;
            align-items: center;
            justify-content: center;
            background-color: #fff;

            &::after {
                content: "";
                border-radius: 50%;
                position: absolute;
                background-color: $primary;
            }
        }

        &::after {
            content: "";
            top: 0px;
            width: 1px;
            left: -16px;
            height: 100%;
            position: absolute;
            background-color: $hr-border-color;
        }

        &:nth-child(1) {
            &::after {
                top: 12px;
            }
        }

        &:nth-last-child(1) {
            &::after {
                height: calc(100% - 70px);
                @include media-breakpoint-up(sm) {
                    height: calc(100% - 34px);
                }
            }

            .circle {
                top: 8px;
                &::after {
                    background-color: $red;
                }
            }
        }
    }
}
